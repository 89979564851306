<template>
  <div id="countries">
    <states-list :show-sidebar.sync="showSidebar" :states="states" :country="selectedCountry" />

    <b-card class="mb-0" body-class="p-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- 
        Countries Table
       -->
      <b-table
        ref="refCountriesTable"
        class="position-relative"
        :items="fetchCountries"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(name)="data">
          <b-button @click="openStates(data.item)" variant="link">{{ data.item.name }}</b-button>
        </template>
        <template #cell(currency)="data">
          {{ data.item.currency }} - {{ data.item.currencySymbol }}
        </template>

        <template #cell(isActive)="data">
          <b-form-checkbox
            v-model="data.item.isActive"
            checked="true"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeCountryStatus(data.item._id, $event)"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCountries"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSpinbutton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useCountriesList from "./useCountriesList";
import { ref, onUnmounted } from "@vue/composition-api";
import StatesList from "./StatesList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSpinbutton,
    BFormCheckbox,
    StatesList,

    vSelect,
  },

  setup() {
    const showSidebar = ref(false);
    const selectedCountry = ref({});
    const states = ref([]);

    const {
      perPage,
      perPageOptions,
      searchQuery,
      fetchCountries,
      tableColumns,
      resolveCountryStatusVariant,
      refCountriesTable,
      totalCountries,
      currentPage,
      dataMeta,

      countryStatuses,
      refetchData,
      changeCountryStatus,
      getCountry,
    } = useCountriesList();

    const openStates = async (country) => {
      states.value = await getCountry(country._id);
      selectedCountry.value = country;
      showSidebar.value = true;
    };

    return {
      perPage,
      perPageOptions,
      totalCountries,
      searchQuery,
      fetchCountries,
      tableColumns,
      resolveCountryStatusVariant,
      refCountriesTable,
      totalCountries,
      currentPage,
      dataMeta,
      openStates,

      countryStatuses,
      refetchData,
      changeCountryStatus,
      showSidebar,
      selectedCountry,
      states,
      getCountry,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
