<template>
  <b-sidebar
    id="states-sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :title="`States for ${country.name}`"
    right
    :visible="showSidebar"
    @change="(val) => $emit('update:show-sidebar', val)"
  >
    <div class="p-0">
      <b-table
        :items="states"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      ></b-table>
    </div>
  </b-sidebar>
</template>
<script>
import { BSidebar, BTable } from "bootstrap-vue";
export default {
  components: {
    BSidebar,
    BTable,
  },
  props: {
    showSidebar: {
      required: true,
      type: Boolean,
    },
    country: {
      required: true,
      type: Object,
    },
    states: {
      required: true,
      type: Array,
    },
  },
  setup() {
    const tableColumns = [{ key: "id" }, { key: "name" }];

    return {
      tableColumns,
    };
  },
};
</script>
