import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import country from '@/store/country/country'

export default function useCountriesList() {
  const toast = useToast()
  const refCountriesTable = ref(null)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = ref(10)
  const searchQuery = ref('')
  const totalCountries = ref(0)
  const currentPage = ref(1)
  const isAddNewSidebarVisible = ref(false)

  const countryStatuses = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const changeCountryStatus = async (countryID, event) => {
    try {
      const formData = new FormData()
      formData.append('isActive', event)
      const response = await axios.put(`countries/${countryID}`, formData)
      return response
    } catch {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Unable to update Country Status',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const fetchCountries = async () => {
    try {
      const formData = new FormData()
      formData.append('q', searchQuery.value)
      formData.append('page', currentPage.value)
      formData.append('perPage', perPage.value)

      const response = await axios.post('countries/search', formData)
      const { data } = response
      totalCountries.value = data.count
      return data.data
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: error.response.status === 401 ? 'you dont have permissions to do this action' : 'Error fetching countries list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const getCountry = async countryID => {
    try {
      const country = await axios.get(`countries/${countryID}`)
      return country.data.data.states
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Unable to fetch the states',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const tableColumns = [
    { key: 'name', sortable: false },
    { key: 'code', sortable: false },
    { key: 'currency' },
    { key: 'region', sortable: false },
    { key: 'subRegion' },
    { key: 'isActive', sortable: false, label: 'Status' },
  ]

  const resolveCountryStatusVariant = status => {
    if (status == 1) return 'success'
    return 'danger'
  }

  const dataMeta = computed(() => {
    const localItemsCount = refCountriesTable.value ? refCountriesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCountries.value,
    }
  })

  const refetchData = () => {
    refCountriesTable.value.refresh()
  }

  // let's watch if there is something changed in the country
  watch([perPage, searchQuery, currentPage], () => {
    refetchData()
  })

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchCountries,
    tableColumns,
    resolveCountryStatusVariant,
    refCountriesTable,
    totalCountries,
    currentPage,
    dataMeta,
    isAddNewSidebarVisible,
    countryStatuses,
    refetchData,
    changeCountryStatus,
    getCountry,
  }
}
